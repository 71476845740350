<template>
  <ul class="menu-nav">
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <button class="menu-link" id="dp_aside_toggle" ref="dp_aside_toggle">
        <i class="menu-icon fas fa-bars text-dark-50 text-hover-dark"></i>
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import DPLayoutBrand from '@/assets/js/layout/base/brand.js';
import DPLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';

export default {
  name: 'DPMenu',
  computed: {
    ...mapGetters(['currentUser']),
  },
  mounted() {
    // Init Brand Panel For Logo
    DPLayoutBrand.init(this.$refs['dp_brand']);

    // Init Aside Menu Toggle
    DPLayoutAsideToggle.init(this.$refs['dp_aside_toggle']);
  },
};
</script>

<style lang="scss">
.header-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  width: 100% !important;
}
</style>
